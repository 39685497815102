import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalEdit: false,
        ph: {
            ph_id: '',
            ph_nama: '',
            ph_ket: '',
            ph_file: '',
            ph_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalEdit = value
        },
        viewModal(state, value) {
            state.ph = value
        }
    }
})
