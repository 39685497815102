<template>
  <v-dialog v-model="ModalAdd" :width="CWidth">
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Tambah Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Tambah Produk Hukum</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-5 mt-5">
          <v-row class="mb-5">
            <v-col cols="8">
              <v-card outlined height="572" class="mt-2">
                <iframe
                  :src="defaultPDF"
                  type="application/pdf"
                  height="100%"
                  width="100%"
                ></iframe>
              </v-card>
            </v-col>

            <v-col cols="4">
              <v-col cols="12" class="mb-n10">
                <span class="subtitle-2">Nama Produk Hukum</span>
                <v-text-field
                  dense
                  flat
                  outlined
                  class="mt-2"
                  autocomplete="off"
                  maxlength="30"
                  v-model="ph_nama"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="mb-n10">
                <span class="subtitle-2">Keterangan Produk Hukum</span>
                <v-textarea
                  dense
                  flat
                  outlined
                  class="mt-2"
                  autocomplete="off"
                  maxlength="250"
                  v-model="ph_ket"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <span class="subtitle-2">File Produk Hukum</span>
                <v-file-input
                  dense
                  flat
                  outlined
                  prepend-icon
                  class="mt-2"
                  accept=".pdf"
                  placeholder="Pilih File"
                  append-icon="mdi-file-pdf-box"
                  @change="onFile"
                ></v-file-input>
              </v-col>
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn
              v-if="btnLoading"
              small
              color="primary"
              depressed
              @click="add()"
              >SIMPAN</v-btn
            >
            <v-btn v-else small color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import refreshView from "@/store/informasi/produkhukum/viewProdukhukum";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  data: () => ({
    session: "",
    ModalAdd: false,
    btnLoading: true,
    CWidth: "70%",

    ph_nama: "",
    ph_ket: "",
    ph_file: "",
    defaultPDF: "",
  }),

  methods: {
    default() {
      this.ph_nama = "";
      this.ph_file = "";
      this.ph_ket = "";
      this.defaultPDF = "";
    },

    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.defaultPDF =
        process.env.VUE_APP_API_BASE + "upload/phFile/pdf-default.pdf";
      this.ModalAdd = true;
    },

    closeModal() {
      this.default();
      this.ModalAdd = false;
    },

    async add() {
      this.btnLoading = false;

      const data = new FormData();
      data.append("ph_nama", this.ph_nama);
      data.append("ph_ket", this.ph_ket);
      data.append("ph_file", this.ph_file);

      const url = process.env.VUE_APP_API_BASE + "produkhukum";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.default();
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.default();
          this.closeModal();
        });
    },

    onFile(value) {
      this.ph_file = value;
      this.defaultPDF = URL.createObjectURL(this.ph_file);
    },
  },
};
</script>
